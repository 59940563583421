import { Box, Typography } from "components"
import { BasePreviewProps } from "."

export const CurrencyPreviewComponent = (props: BasePreviewProps) => {
  const { label, value, unit } = props
  return (
    <>
      <Typography variant="subtitle2" color="Text/Gray Preview">
        {label}
      </Typography>
      <Box mt="4px">
        <Typography variant="h4" color="Text/Black2">
          {value ? value : "0"}
          {unit && " " + unit}
        </Typography>
      </Box>
    </>
  )
}
