import { DialogActions, DialogContent, Dialog as MuiDialog } from "@material-ui/core"
import { Box, Button, Checkbox, Field, Typography, IconButton } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import { isEmpty } from "lodash"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { MuiIcon } from "components/common/MuiIcon"
import parse from "html-react-parser"
import DOMPurify from "isomorphic-dompurify"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 16px;
    min-width: 343px;
  }
`

const ModalContainer = styled(DialogContent)`
  height: 100%;
  padding: 16px 16px 0px 16px;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

const Header = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  margin-bottom: 16px;
  .closeBtn {
    border: none;
    position: absolute;
    right: 0px;
  }
`

const Main = styled("div")`
  overflow-y: scroll;
  color: ${AppColor["Text/Secondary"]};
  min-height: 424px;
  max-height: 500px;
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void
  handleChangeCheck: () => void
  checkHaveConsent: any
  isDisabledOkButton: boolean
}

const ConsentModalComponent = (props: ApproveModalProps) => {
  return (
    <Dialog open={props.isOpen}>
      <ModalContainer>
        <>
          <Header>
            <Typography className="title" variant="Body/16" color={AppColor["Primary/Primary Text"]}>
              {props.checkHaveConsent?.title}
            </Typography>
            <IconButton
              className="closeBtn"
              color={AppColor["Primary/Primary Text"]}
              variant="text"
              onClick={props.handleClose}
              style={{ padding: "0px" }}
            >
              <MuiIcon name="Close" fontSize="small" />
            </IconButton>
          </Header>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Main>
              <Typography className="title" variant="Body/14" color={AppColor["Text/Placeholder"]}>
                {parse(DOMPurify.sanitize(props.checkHaveConsent?.description || "", { ADD_ATTR: ["target"] }))}
              </Typography>
            </Main>
          </div>
          {!isEmpty(props.checkHaveConsent?.confirmCheckboxLabel) && (
            <Field
              p={1}
              component={Checkbox}
              name="confirmConsent"
              className="submitTransferFast"
              label={props.checkHaveConsent?.confirmCheckboxLabel || ""}
              onChange={props.handleChangeCheck}
            />
          )}
        </>
      </ModalContainer>
      <DialogActions>
        <ActionContainer>
          <ActionButton variant="outlined" onClick={props.handleClose} mr="16px">
            ปิดหน้าต่างนี้
          </ActionButton>
          <ActionButton variant="contained" onClick={props.handleConfirm} disabled={props.isDisabledOkButton}>
            ยอมรับ
          </ActionButton>
        </ActionContainer>
      </DialogActions>
    </Dialog>
  )
}

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks
    const { isOpen, onClose, onConfirm, checkHaveConsent } = props

    const [isDisabledOkButton, setIsDisabledOkButton] = useState(checkHaveConsent?.confirmCheckboxLabel ? false : true)

    const handleChangeCheck = useCallback((event: any) => {
      setIsDisabledOkButton(!event.target.checked)
    }, [])

    const handleClose = useCallback(() => {
      setIsDisabledOkButton(true)
      onClose()
    }, [onClose])

    const handleConfirm = useCallback(() => {
      onConfirm()
      handleClose()
    }, [onConfirm, handleClose])

    return {
      isOpen,
      handleClose,
      handleConfirm,
      checkHaveConsent,
      handleChangeCheck,
      isDisabledOkButton,
    }
  }),
)

const ConsentModal = enhancer(ConsentModalComponent)

export default ConsentModal
