import { BaseStore } from "./index"
import server from "common/api"
import { clone, mapValues } from "lodash"

class SettingStore extends BaseStore {
  constructor() {
    super("settingStore", {
      setting: null,
    })
  }

  setSetting = (setting) => {
    this.setState({
      setting,
    })
  }
}

export default new SettingStore()
