/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Field, Typography } from "components"
import { INPUT_TYPE } from "constants/enums/input-type"
import { mappingComponent } from "."

import { AppColor } from "theme/app-color"
import { PreviewField } from "./preview"
import { IconName } from "components/common/Icon"
import { compose, withHooks } from "enhancers"
import { keys, filter, isEmpty, intersection, forEach } from "lodash"
import { withStyles } from "@material-ui/core"
import { functions } from "configs"

export interface RelationValue {
  id: string
  name: string
  relation: string
}

export type valueType = string | RelationValue

export interface CustomFieldProps {
  component: keyof typeof INPUT_TYPE
  name: string
  label: string
  options?: any[]
  isPreview?: boolean
  value?: valueType
  disabled?: boolean
  disableClearable?: boolean
  unit?: string
  required?: boolean
  icon?: IconName
  iconColor?: string
  placeholder?: string
  helperText?: string
  master?: any
  requester?: any
  auto?: any[]
  requestName?: string
  values?: any
  setFieldValue?: any
  fast?: boolean
  defaultValue?: any
  onFocus?: any
  onBlur?: any
  helperTextColor?: any
  maxDate?: any
  minDate?: any
  filterOptions?: object
  backgroundColor?: any
  textColor?: any
  setAdditionalList?: () => void
  additionalList?: string[]
}

interface StyledFieldProps {
  helperTextColor?: string // Define the type of the error prop
  backgroundColor?: string // Define the type of the error prop
  textColor?: string // Define the type of the error prop
}

const StyledField = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: (props: StyledFieldProps) => props.helperTextColor,
      lineHeight: "20px",
      margin: "5px 0px 0px 0px",
      fontSize: 14,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: (props: StyledFieldProps) => props.textColor,
      background: (props: StyledFieldProps) => props.backgroundColor,
      opacity: 1,
    },
  },
})(Field)

const CustomField = (props: any) => (
  <>
    {props.component === INPUT_TYPE.SUM_AMOUNT ? (
      <></>
    ) : (
      <>
        {props.isPreview && (
          <PreviewField
            previewType={props.component}
            previewProps={{
              label: props.label,
              value: props.value,
              component: props.component,
              unit: props.unit,
              requester: props.requester,
            }}
          />
        )}
        {!props.isPreview && (
          <>
            {props.component !== INPUT_TYPE.LABEL && props.component !== INPUT_TYPE.CHECKBOX && (
              <Typography variant="body1">
                <span>{props.autoLabel}</span>
                <span style={{ color: AppColor["Other/Danger"] }}>{props.isRequired ? " *" : ""}</span>
              </Typography>
            )}

            <Box display={props.unit ? "flex" : ""}>
              <StyledField
                key={props.autoLabel}
                fast={props.fast}
                options={props.options}
                component={props.components}
                name={props.name}
                disabled={props.isDisabled}
                inputProps={{
                  style: {
                    textAlign: props.unit ? "end" : "",
                  },
                }}
                fullWidth
                freeSolo={props.name === "province_departure_place" || props.name === "province_arrival_place"}
                disableClearable={props.disableClearable}
                title={props.autoLabel}
                icon={props.icon}
                iconColor={props.iconColor}
                placeholder={props.autoPlaceholder}
                helperText={props.autoRemark}
                master={props.master}
                requester={props.requester}
                helperTextColor={props.helperTextColor}
                maxDate={props.maxDate}
                minDate={props.minDate}
                filterOptions={props.filterOptions}
                backgroundColor={props.autoBackgroundColor}
                textColor={props.autoTextColor}
                setAdditionalList={props.setAdditionalList}
                additionalList={props.additionalList}
              />
              {props.unit && (
                <Box display="flex" alignItems="center" justifyContent="flex-end" ml={4} minWidth="27px">
                  <Typography variant="h4" color="Text/Black2">
                    {props.unit}
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
        {!props.isPreview && !props.component && (
          <Typography variant="body1" color={AppColor["Other Light/Danger"]}>
            Component not found. Please contact admin to check settings
          </Typography>
        )}
      </>
    )}
  </>
)

const enhancer = compose(
  withHooks((props: CustomFieldProps, hooks: any) => {
    const {
      component,
      name,
      auto = [],
      values = {},
      requestName = "",
      setFieldValue,
      disabled,
      required,
      label,
      helperText,
      placeholder,
      defaultValue,
      requester,
      backgroundColor,
      textColor,
    } = props
    const { useAutoCal, useEffect, useState, useMemo, useCallback, usePrevious } = hooks
    const [isDisabled, setIsDisabled] = useState(disabled)
    const [isRequired, setIsRequired] = useState(required)
    const [autoLabel, setAutoLabel] = useState(label)
    const [autoRemark, setAutoRemark] = useState(helperText)
    const [autoPlaceholder, setAutoPlaceholder] = useState(placeholder)
    const [autoBackgroundColor, setAutoBackgroundColor] = useState(backgroundColor)
    const [autoTextColor, setAutoTextColor] = useState(textColor)

    const userInfo = useMemo(() => {
      return requester
    }, [requester])

    const defaultPropsValues = useMemo(
      () => ({
        value: defaultValue,
        required,
        disabled,
        label,
        helperText,
        placeholder,
      }),
      [disabled, helperText, label, placeholder, required, defaultValue],
    )
    const autoCal = useAutoCal({
      requestName,
      name,
      auto,
    })

    const autoCalResolver = useCallback(
      (autoCalFunc: any, values: any, defaultPropsValues: any, changedFieldNames: any, fieldName: string) => {
        const relatedFieldNames: string[] = []

        const trackerValues = {}
        forEach(values, (value, key) => {
          Object.defineProperty(trackerValues, key, {
            get: function () {
              relatedFieldNames.push(key)
              return value
            },
          })
        })

        const calculatedValue = autoCalFunc(trackerValues, defaultPropsValues, userInfo)

        const hasRelatedFieldChanged = !isEmpty(intersection(relatedFieldNames, changedFieldNames))

        if (!hasRelatedFieldChanged && values[fieldName] !== undefined) {
          return
        }

        return calculatedValue
      },
      [userInfo],
    )

    const autoCalFunc = autoCal.value

    const previousValues = usePrevious(values)

    useEffect(() => {
      // Ignore autocal when does not have function
      if (!autoCalFunc) {
        return
      }

      const changedFieldNames = filter(keys(values), (key) => {
        return previousValues?.[key] !== values?.[key]
      })

      const calculatedValue = autoCalResolver(autoCalFunc, values, defaultPropsValues, changedFieldNames, name)
      if (calculatedValue === undefined) {
        return
      }

      setFieldValue(name, calculatedValue)

      // Ignore autocal when calculate function return undefined
    }, [autoCalFunc, defaultPropsValues, values])

    useEffect(() => {
      const autoCalRequired = autoCal.required
      // before refactor if (autoCalRequired) setIsRequired(autoCalRequired(values, defaultPropsValues))
      setAutoCal(autoCalRequired, setIsRequired, values, defaultPropsValues, userInfo)
    }, [autoCal.required, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoCalDisabled = autoCal.disabled
      // before refactor if (autoCalDisabled) setIsDisabled(autoCalDisabled(values, defaultPropsValues))
      setAutoCal(autoCalDisabled, setIsDisabled, values, defaultPropsValues, userInfo)
    }, [autoCal.disabled, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoCalLabel = autoCal.label
      // before refactor if (autoCalLabel) setAutoLabel(autoCalLabel(values, defaultPropsValues))
      setAutoCal(autoCalLabel, setAutoLabel, values, defaultPropsValues, userInfo)
    }, [autoCal.label, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoCalRemark = autoCal.remark
      // before refactor if (autoCalRemark) setAutoRemark(autoCalRemark(values, defaultPropsValues))
      setAutoCal(autoCalRemark, setAutoRemark, values, defaultPropsValues, userInfo)
    }, [autoCal.remark, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoCalPlaceholder = autoCal.placeholder
      // before refactor if (autoCalPlaceholder) setAutoPlaceholder(autoCalPlaceholder(values, defaultPropsValues))
      setAutoCal(autoCalPlaceholder, setAutoPlaceholder, values, defaultPropsValues, userInfo)
    }, [autoCal.placeholder, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoBackgroundColor = autoCal.backgroundColor
      // before refactor if (autoBackgroundColor) setAutoRemarkColor(autoBackgroundColor(values, defaultPropsValues))
      setAutoCal(autoBackgroundColor, setAutoBackgroundColor, values, defaultPropsValues, userInfo)
    }, [autoCal.backgroundColor, defaultPropsValues, values, userInfo])

    useEffect(() => {
      const autoTextColor = autoCal.textColor
      // before refactor if (autoTextColor) setAutoRemarkColor(autoTextColor(values, defaultPropsValues))
      setAutoCal(autoTextColor, setAutoTextColor, values, defaultPropsValues, userInfo)
    }, [autoCal.textColor, defaultPropsValues, values, userInfo])

    return {
      ...props,
      components: mappingComponent(INPUT_TYPE[component]),
      isDisabled,
      isRequired,
      autoLabel,
      autoRemark,
      autoPlaceholder,
      maxDate: props.maxDate !== undefined ? functions.calculate[props.maxDate]() : undefined,
      minDate: props.minDate !== undefined ? functions.calculate[props.minDate]() : undefined,
      autoBackgroundColor,
      autoTextColor,
    }
  }),
)

const setAutoCal = (autoCalFunc: any, setAuto: any, values: any, defaultPropsValues: any, userInfo: any) => {
  if (autoCalFunc) setAuto(autoCalFunc(values, defaultPropsValues, userInfo))
}

export default enhancer(CustomField)
