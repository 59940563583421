import { useMsal } from "@azure/msal-react"
import { removeToken, setToken } from "api"
import { Box, Button, Field, Form, Link, TextField, Typography } from "components"
import { loginRequest } from "constants/authConfig"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import styled from "styled-components/macro"
import { AppColor } from "theme/app-color"
import { paths, gql } from "utils/helper"
import * as yup from "yup"
import { ReactComponent as Logo } from "assets/image/bam_logo.svg"
import { ReactComponent as Header } from "assets/image/bgHeader.svg"
import AuthModal from "./AuthModal"
import { call } from "common/helper"
import { appSignInTitle } from "constants/objects/title"

interface SignInPageProps {
  handleClickLogin: () => void
  hasAccount: boolean
  handleClickLogoutAzure: () => void
  account: any
  currentUserInfo: any
  title: string
  description: string
  isModalOpen: boolean
  isError: boolean
}

const LoginAzureButton = styled(Button)`
  width: 345px;
  margin: 12px 0px;
  border-radius: 12px;
`

const SignInPage = ({
  handleClickLogin,
  hasAccount,
  handleClickLogoutAzure,
  account,
  currentUserInfo,
  title,
  description,
  isModalOpen,
  isError,
}: SignInPageProps) => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    {hasAccount ? (
      <>
        <AuthModal title={title} isOpen={true} description={description} isError={isError} />
      </>
    ) : (
      <>
        <Box display="flex" flexDirection="column" alignItems="center" position="relative" mb={3}>
          <Header />
          <Box position="absolute" mt={12}>
            <Logo />
          </Box>
        </Box>

        <Typography variant="h1" color="Primary/Primary Text">
          {appSignInTitle}
        </Typography>
        <Typography
          variant="Sub Desc"
          color="Text/Placeholder"
          style={{ maxWidth: 345, marginBottom: 24, marginTop: 40, letterSpacing: "0.6px" }}
        >
          *ระบบสารสนเทศนี้เป็นทรัพย์สินของ บสก.
          การใช้งานระบบจะต้องได้รับการอนุมัติก่อนการใช้งานเท่านั้นผู้ที่ไม่ได้ผ่านการอนุมัติตามขั้นตอนของบริษัทจะถือเป็นผู้บุกรุก
          หากมีการตรวจสอบ <br /> จะถือเป็นความผิดที่จะต้องมีการลงโทษทางวินัย หรือดำเนินการทางกฎหมายตามแต่กรณี บสก.
          สงวนสิทธิ์ในการตรวจสอบและติดตามพฤติกรรมการใช้งานในระหว่างที่ผู้ใช้งานระบบนี้ได้โดยไม่ถือว่าเป็นการละเมิดความเป็นส่วนตัว
        </Typography>
        <LoginAzureButton variant="contained" onClick={handleClickLogin}>
          เข้าสู่ระบบด้วย Azure AD
        </LoginAzureButton>
      </>
    )}
    <AuthModal title={title} isOpen={isModalOpen} description={description} isError={isError} />
  </Box>
)

export const API = {
  LOGIN: gql`
    mutation LOGIN($email: String!, $password: String) {
      login(input: { email: $email, password: $password }) {
        userToken
        employee {
          employeeCode
          firstName
          lastName
        }
      }
    }
  `,
  VALIDATE_EMAIL: gql`
    query VALIDATE_EMAIL($email: String!) {
      validateEmail(input: { email: $email })
    }
  `,
}

const validationSchema = yup.object().shape({
  email: yup.string().email("รูปแบบอีเมลไม่ถูกต้อง").required("อีเมลต้องไม่เว้นว่าง"),
})

const enhancer = compose(
  withFormik({ validationSchema }),
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useMemo, useCallback, useState, useEffect, useLazyQuery } = hooks
    const { setErrors, currentUserInfo, values, isValid } = props
    const [title, setTitle] = useState("เข้าสู่ระบบ")
    const [description, setDescription] = useState("กำลังเชื่อมต่อ Azure AD")
    const [isModalOpen] = useState(false)
    const [isError, setIsError] = useState(false)

    const [login] = useMutation(
      API.LOGIN,
      {
        fetchPolicy: "network-only",
        onCompleted: (data: any) => {
          const { login } = data
          setToken(login.userToken)
          window.location.href = paths.landingPath()
        },
        onError: (data: any) => {
          setErrors(data.message)
          if (values.password) {
            setErrors({
              email: data.message,
              password: data.message,
            })
          } else {
            setErrors({
              email: data.message,
            })
          }
        },
      },
      [],
    )

    const [validateEmail] = useLazyQuery(API.VALIDATE_EMAIL, {
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      call(async () => {
        if (isValid && values.email) {
          const { data } = await validateEmail({ variables: { email: values.email } })
          if (!data.validateEmail) {
            setErrors({ email: "ชื่อผู้ใช้นี้ไม่ได้รับสิทธิ์ในการเข้าถึงระบบ" })
          }
        }
      })
    }, [values.email, isValid, validateEmail, setErrors])

    useHandleSubmit(async (value: any) => {
      const { email, password } = value
      login({
        variables: {
          email: email.trim(),
          password: password,
        },
      })
    }, [])

    const { instance, accounts, inProgress } = useMsal()

    const handleClickLogin = useCallback(() => {
      instance
        .loginRedirect(loginRequest)
        .then((response) => {
          // setIsModalOpen(true)
          instance.ssoSilent(loginRequest)
          console.log(response)
          setTitle("เข้าสู่ระบบ")
        })
        .catch((error) => {
          // handle error, either in the library or coming back from the server
          console.log("Error msal !!!!! : ", error)
          setTitle("เกิดข้อผิดพลาด")
          setDescription(error)
          setIsError(true)
        })
    }, [instance])

    const hasAccount = useMemo(() => {
      return accounts.length > 0
    }, [accounts])

    const handleClickLogoutAzure = useCallback(() => {
      if (hasAccount) {
        instance.logout({
          account: accounts[0],
          onRedirectNavigate: (url) => {
            instance.setActiveAccount(null)
            removeToken()
            window.location.href = paths.landingPath()
            return true
          },
        })
        instance.setActiveAccount(null)
      }
    }, [hasAccount, instance, accounts])

    const account = useMemo(() => {
      if (hasAccount) return accounts[0]
    }, [hasAccount, accounts])

    return {
      handleClickLogoutAzure,
      handleClickLogin,
      hasAccount,
      account,
      currentUserInfo,
      title,
      description,
      isModalOpen,
      inProgress,
      isError,
    }
  }),
)

export default enhancer(SignInPage)
