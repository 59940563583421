import { Box, Typography } from "components"
import { env } from "configs"
import { BasePreviewProps } from "."
const checkIsObj = (relation: any, value: any, requester: any) => {
  return typeof value === "object"
    ? `[${relation[value.relation as keyof typeof relation]}] ${value.name}`
    : `[${relation[value as keyof typeof relation]}] ${requester.firstName} ${requester.lastName}`
}

export const RelationPreviewComponent = (props: BasePreviewProps) => {
  const { label, value, requester } = props
  const name = value ? checkIsObj(env.MAPPING_DEPENDENT_RELATIONS, value, requester) : "-"
  return (
    <>
      <Typography variant="subtitle2" color="Text/Gray Preview">
        {label}
      </Typography>
      <Box mt="4px">
        <Typography variant="h4" color="Text/Black2">
          {name}
        </Typography>
      </Box>
    </>
  )
}
