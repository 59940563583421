import { compose, withHooks } from "enhancers"
import { Select } from "components"
import { Option } from "constants/interface/options"
import { isEmpty, uniqBy, get } from "lodash"

const CustomSelect = (props: any) => <Select {...props} />

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { options = [], name, master, field } = props
    const { useMemo, useCallback } = hooks

    const getOptions = useCallback(
      (path: string, name?: string) => {
        if (path && !isEmpty(path)) {
          if (name === "district_departure_place" || name === "district_distances") {
            const provinceData = uniqBy(
              get(master, path.split(".")[1]).map((option: any) => {
                const [district] = option.label.split(" » ")
                return { label: district, value: district }
              }),
              "label",
            )
            return provinceData
          } else return get(master, path.split(".")[1]) as Option[]
        }
        return []
      },
      [master],
    )

    const customOptions = useMemo(() => {
      if (typeof options === "string") {
        return getOptions(options, name)
      }
      return options
    }, [options, getOptions, name])

    return {
      ...props,
      options: customOptions,
    }
  }),
)

export default enhancer(CustomSelect)
