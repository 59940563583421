import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { ClaimRequestHistoryItem } from "components/advance/ClaimRequestHistoryItem"
import { Box, Typography } from "components/common"
import Loader from "components/Loader"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withFormik, withHooks, withStores } from "enhancers"
import { every, isEmpty } from "lodash"
import InfiniteScroll from "react-infinite-scroll-component"
import paths from "routes/paths"
import styled from "styled-components"

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`

const Contents = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const StatusTabPage = (props: any) => (
  <>
    {props.loading && <Loader />}
    {props.showEmptyPage && (
      <ContentContainer>
        <Contents>
          <Typography variant="h3" color="Text/Primary Text">
            ไม่มีรายการขอเบิก
          </Typography>
          <Typography variant="body1" color="Text/Gray Preview" style={{ fontSize: "16px", marginTop: "17px" }}>
            ไม่พบข้อมูลการขอเบิกในสถานะนี้
          </Typography>
        </Contents>
      </ContentContainer>
    )}
    {props.showNotFoundSearch ? (
      <Box>
        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Typography variant="h3" color="Gray/Secondary Text">
            รายการค้นหา
          </Typography>
          <Typography variant="subtitle2" color="Gray/Primary Text">
            (0 รายการ)
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="fixed"
          top="40%"
          left="10%"
          right="10%"
        >
          <Typography variant="h3" color="Gray/Secondary Text">
            ไม่พบรายการที่ต้องการ
          </Typography>
          <Typography variant="h4" color="Text/Placeholder">
            กรุณาตรวจสอบข้อมูลตัวกรองที่ใช้ค้นหาอีกครั้ง
          </Typography>
        </Box>
      </Box>
    ) : (
      <InfiniteScroll
        dataLength={props.claimRequestList.length ?? 0}
        next={props.fetchMoreData}
        hasMore={props.hasMore}
        loader={<Loader />}
        height={props.height}
      >
        {props.claimRequestList.map((history: any) => (
          <ClaimRequestHistoryItem
            key={history.id}
            id={history.id}
            info={history.info}
            workflowSeq={history.workflowSeq}
            createdAt={history.createdAt}
            referenceId={history.referenceId}
            amount={props.requestAmount(history)}
            onClick={(id: string) => props.handleClickHistoryItem(id)}
            owner={history.employee}
            createdBy={history.createdBy}
            userRole={props.currentUser.role}
            status={history.status}
            filteredStatus={props.filteredStatus} // เฉพาะหน้ารายการเบิก
            workflowApproved={history.workflowApproved}
            totalWorkflow={history.totalWorkflow}
            myApprovalStatus={history.myApprovalStatus}
          />
        ))}
      </InfiniteScroll>
    )}
  </>
)

const API = {
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!, $filters: JSON, $pagination: JSON) {
      claimRequestList(input: { status: $status, filters: $filters, pagination: $pagination }) {
        workflowSeq
        id
        type
        status
        info
        employee
        createdAt
        createdBy
        referenceId
        currentWorkflow

        workflowApproved
        totalWorkflow
        myApprovalStatus
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { externalCanSubmit, currentUserInfo, waitingRequest, windowHeight, status } = props

    const { useEffect, useCallback, useMemo, useState, useQuery, useUrlParam } = hooks

    const queryParams = useUrlParam()
    const { filters = {} } = queryParams

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const canSubmit = useMemo(() => {
      return externalCanSubmit
    }, [externalCanSubmit])

    const { data, refetch, fetchMore, loading, error } = useQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: {
        status,
        filters: filters,
        pagination: { offset: 0, limit: 10 },
      },
      onCompleted: (data: any) => {
        if (data?.claimRequestList.length === 0) {
          setHasMore(false)
        }
      },
      fetchPolicy: "network-only",
    })

    const claimRequestListData = useMemo(() => {
      if (loading || error) {
        return []
      }

      return data?.claimRequestList || []
    }, [data, loading, error])

    const [hasMore, setHasMore] = useState(true)
    const [hasFilter, setHasFilter] = useState(false)

    const handleClickHistoryItem = useCallback(
      (id: string) => {
        paths.approvalRequestPath(id, { tab: status, filters: filters }).push()
      },
      [filters, status],
    )

    const refetchClaimList = useCallback(async () => {
      await refetch({
        status: status,
        filters: filters,
        pagination: { offset: 0, limit: 10 },
      })
      const filterValues = filters
      if (!isEmpty(filterValues)) {
        setHasFilter(true)
      }
      if (every(filterValues, (value) => !value)) {
        setHasFilter(false)
      }
    }, [refetch, status, filters])

    const showNotFoundSearch = useMemo(() => claimRequestListData.length === 0 && hasFilter, [
      claimRequestListData,
      hasFilter,
    ])

    const fetchMoreData = useCallback(() => {
      fetchMore({
        variables: {
          status: status,
          filters: filters,
          pagination: {
            offset: claimRequestListData.length,
            limit: 10,
          },
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (fetchMoreResult.claimRequestList) setHasMore(fetchMoreResult.claimRequestList.length > 0)
          if (!fetchMoreResult) return prev
          return {
            claimRequestList: [...prev.claimRequestList, ...fetchMoreResult.claimRequestList],
          }
        },
      })
    }, [fetchMore, status, filters, claimRequestListData.length])

    useEffect(() => {
      if (canSubmit) {
        const fetchData = () => {
          refetchClaimList()
        }
        fetchData()
      }
    }, [canSubmit, refetchClaimList])

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    useEffect(() => {
      if (status === EnumClaimRequestStatus.WAITING) {
        waitingRequest && waitingRequest(claimRequestListData)
      }
    }, [waitingRequest, status, claimRequestListData])

    const showEmptyPage = useMemo(() => {
      return claimRequestListData.length === 0 && !hasMore && !hasFilter && !loading
    }, [claimRequestListData, hasMore, hasFilter, loading])

    return {
      hasMore,
      claimRequestList: claimRequestListData,
      handleClickHistoryItem,
      hasFilter,
      showNotFoundSearch,
      fetchMoreData,
      requestAmount,
      currentUser,
      height: windowHeight,

      loading,

      showEmptyPage,
      filteredStatus: status,
    }
  }),
)

const ClaimListTab = enhancer(StatusTabPage)

export default ClaimListTab
