import { gql, useQuery } from "@apollo/client"
import { compose, withHooks } from "enhancers"
import { useCallback, useEffect, useMemo } from "react"
import paths from "routes/paths"

import { useMsal } from "@azure/msal-react"
import { getToken, removeToken, setSuperToken, setToken } from "api"
import { Box, Icon, Modal, Redirect, Route, Switch } from "components"
import Loader from "components/Loader"
import Typography from "components/common/Typography"
import { env } from "configs"
import { AES, enc, mode } from "crypto-js"
import { msalInstance } from "index"
import { isEmpty } from "lodash"
import Page404 from "pages/auth/Page404"
import Page498 from "pages/auth/Page498"
import Page500 from "pages/auth/Page500"
import ResetPassword from "pages/auth/ResetPassword"
import SetupPassword from "pages/auth/SetupPassword"
import SignIn from "pages/auth/SignIn"
import ClaimDetailPage from "pages/main/claim-detail/index"
import ClaimRequestPage from "pages/main/claim-requests"
import ApprovalPage from "pages/main/claim-requests/detail"
import EditPage from "pages/main/claim-requests/edit"
import DashboardComponent from "pages/main/dashboard"
import dashboardDetail from "pages/main/dashboard/dashboardDetail"
import HistoryComponent from "pages/main/e-claim-history"
import HistoryDetailComponent from "pages/main/e-claim-history/detail"
import eClaimLanding from "pages/main/e-claim-landing"
import ListPageComponent from "pages/main/e-claim-list/index"
import { useIdleTimer } from "react-idle-timer"
import userStore from "stores/userStore"
import { AppColor } from "theme/app-color"
import HistoryDetailPreviewPage from "pages/main/e-claim-history/detail/preview"
import { useLocation } from "react-router-dom"
import DebugSignIn from "pages/auth/DebugSignIn"
import ConsiderationDetailComponent from "pages/main/consideration-detail"
import settingStore from "stores/settingStore"

const MainPages = () => (
  <Switch>
    <Route path={paths.landingPath()} exact component={eClaimLanding} />
    <Route path={paths.claimDetailPath()} exact component={ClaimDetailPage} />
    <Route path={paths.listPath()} exact component={ListPageComponent} />
    <Route path={paths.dashboardPath()} exact component={DashboardComponent} />
    <Route path={paths.dashboardDetailPath(":id")} exact component={dashboardDetail} />
    <Route path={paths.historyPath()} exact component={HistoryComponent} />
    <Route path={paths.historyDetailPath(":id")} exact component={HistoryDetailComponent} />
    <Route path={paths.claimRequestsPath()} exact component={ClaimRequestPage} />
    <Route path={paths.approvalRequestPath(":id")} exact component={ApprovalPage} />
    <Route path={paths.editDraftRequestPath(":id")} exact component={EditPage} />
    <Route path={paths.considerationDetailPath()} exact component={ConsiderationDetailComponent} />

    <Redirect to={paths.landingPath()} />
  </Switch>
)

const GuestPages = () => (
  <Switch>
    <Route path={paths.page404Path()} exact component={Page404} />

    <Route path={paths.page500Path()} exact component={Page500} />
    <Route path={paths.page498Path()} exact component={Page498} />

    <Route path={paths.signInPath()} exact component={SignIn} />
    <Route path={paths.resetPasswordPath()} exact component={ResetPassword} />
    <Route path={paths.setupPasswordPath(":id")} exact component={SetupPassword} />
    <Route path={paths.debugSignInPath()} exact component={DebugSignIn} />
    <Redirect to={paths.signInPath()} />
  </Switch>
)
const PreviewPages = () => {
  return (
    <Switch>
      <Route path={paths.previewPath(":id")} exact component={HistoryDetailPreviewPage} />
      <Redirect to={paths.previewPath(":id")} />
    </Switch>
  )
}

interface RoutesProps {
  initialized: boolean
  isAuthorized: boolean
  loading: boolean
  isPreview: boolean
}

const Routes = (props: RoutesProps) => {
  const { initialized, isAuthorized, loading, isPreview } = props
  if (loading) return <Loader />
  if (isPreview) return <PreviewPages />
  else if (!initialized || !isAuthorized) return <GuestPages />
  else return <MainPages />
}

export const API = {
  SIGN_IN_VIA_AZURE_AD: gql`
    mutation SIGN_IN_VIA_AZURE_AD($email: String!, $authToken: String!, $preferredUserName: String!) {
      signInViaAzureAd(input: { email: $email, authToken: $authToken, preferredUserName: $preferredUserName }) {
        id
        officialMail
        user {
          userToken
          userType
          id
        }
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
  DEBUG_WITH_MS_TEAM: gql`
    mutation DEBUG_WITH_MS_TEAM($tag: String!, $messages: JSON!) {
      debugWithMsTeam(input: { tag: $tag, messages: $messages })
    }
  `,
  GET_SETTING: gql`
    query GET_SETTING {
      setting {
        id
        showDetailOn
        startClaimRequestEnrollment
        endClaimRequestEnrollment
        notifyBeforeStartAt
        images {
          url
          filename
        }
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useState, useUrlParam, useMutation, useParams, useLazyQuery } = hooks
    const { token: encryptedToken, withPortalToken } = useUrlParam()

    const [isReady, setIsReady] = useState(false)
    const [isMsalLoading, setIsMsalLoading] = useState(true)

    const { accounts, instance, inProgress } = useMsal()

    const [fetchSetting] = useLazyQuery(API.GET_SETTING, {
      onCompleted: (data: any) => {
        settingStore.setSetting(data.setting)
      },
      onError: (error: any) => {
        console.log("GET SETTING USER ERROR : ", error)
      },
    })

    const hasAccount = useMemo(() => {
      return accounts.length > 0
    }, [instance, accounts, inProgress])
    const { pathname } = useLocation()
    const urlParams = useUrlParam()
    const currentPath = useMemo(() => {
      return pathname.split("/")[1]
    }, [pathname])
    const isPreview = currentPath === "preview"
    if (isPreview) {
      setSuperToken(urlParams.superToken)
    }
    const errorModal = useCallback((message) => {
      // show modal on error when signin via azure
      const error = message.replace("ApolloError: ", "")
      // @ts-ignore
      return Modal.alert({
        className: "ErrorModal",
        title: "",
        children: (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Icon width="40px" height="40px" name="Warning" color={AppColor["Other/Warning"]} />
            <Box mt="16px">
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                เกิดข้อผิดพลาด
              </Typography>
            </Box>
            <Box mt="16px" pb="16px">
              <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                {error}
              </Typography>
            </Box>
          </div>
        ),
        onOk: () => {
          sessionStorage.clear()
          paths.signInPath().push()
          window.location.reload()
          // @ts-ignore
          Modal.close()
        },
        okButtonVariant: "contained",
      })
    }, [])

    const [signInWithAzureAd] = useMutation(API.SIGN_IN_VIA_AZURE_AD, {
      onCompleted: (data: any) => {
        const { signInViaAzureAd } = data
        console.log("LOGIN WITH AZURE AD: ", signInViaAzureAd)

        if (signInViaAzureAd.user.userToken) {
          setToken(signInViaAzureAd.user.userToken)
          window.location.reload()
        }
        setIsMsalLoading(false)
      },
      onError: (error: any) => {
        console.log("LOGIN WITH AZURE AD ERROR : ", error)
        msalInstance.setActiveAccount(null)
        removeToken()
        setIsMsalLoading(false)
        errorModal(error.message)
      },
    })

    const { loading, error: currentUserError, data: currentUser } = useQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentUser)
        setIsReady(true)
      },
      onError: (error: any) => {
        console.log("GET CURRENT USER ERROR : ", error)
        userStore.setCurrentUser(null)
        setIsReady(false)
        removeToken()
        if (!error.message.includes("Unauthorized!")) errorModal(error.message)
      },
    })

    const [debugWithMsTeam] = useMutation(API.DEBUG_WITH_MS_TEAM)

    const pageLoading = useMemo(() => loading || isMsalLoading, [isMsalLoading, loading])

    const initialized = !pageLoading && isReady
    const isAuthorized = useMemo(() => {
      return !!(!currentUserError && currentUser)
    }, [currentUserError, currentUser])

    useEffect(() => {
      if (withPortalToken) {
        removeToken()
        setToken(withPortalToken)
      }
    }, [withPortalToken])

    useEffect(() => {
      // Sign in with encrypted token
      if (encryptedToken) {
        localStorage.setItem("Encrypted_token", encryptedToken)
        removeToken()
        const secret = env.REACT_APP_LOGIN_SECRET

        let secSpec = enc.Utf8.parse(secret)
        let ivSpec = enc.Utf8.parse("")

        const encode = encodeURI(encryptedToken)
        console.log("encode", encode)

        const decrypted = AES.decrypt(encryptedToken, secSpec, {
          iv: ivSpec,
          mode: mode.CBC,
        })
        const email = decrypted.toString(enc.Utf8)
        if (!isEmpty(email)) {
          signInWithAzureAd({
            variables: {
              email: email,
              authToken: "",
              preferredUserName: email,
            },
          })
        }
      }
    }, [encryptedToken, signInWithAzureAd])

    useEffect(() => {
      // Sign in with Azure AD
      msalInstance
        .handleRedirectPromise()
        .then(async () => {
          const account = accounts[0]
          const currentToken = getToken()

          if (account && !currentToken) {
            debugWithMsTeam({ variables: { tag: "MSAL", messages: account } })
            await signInWithAzureAd({
              variables: {
                email: account?.idTokenClaims?.email,
                authToken: "",
                preferredUserName: account.idTokenClaims?.preferred_username,
              },
            })
          } else {
            setIsMsalLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }, [accounts, signInWithAzureAd, debugWithMsTeam])

    const handleClickLogout = useCallback(() => {
      if (hasAccount) {
        instance.logout({
          account: accounts[0],
          onRedirectNavigate: (url) => {
            instance.setActiveAccount(null)
            removeToken()
            return true
          },
        })
        instance.setActiveAccount(null)
      } else {
        removeToken()
      }
    }, [hasAccount, instance, accounts])

    const expireTimeAlert = () => {
      handleClickLogout()
      // @ts-ignore
      Modal.alert({
        title: "หมดเวลาการใช้งาน",
        children: "กรุณาเข้าสู่ระบบอีกครั้ง เนื่องจากไม่ได้มีการใช้งานระบบเป็นระยะเวลา 15 นาที",
        okButtonLabel: "เข้าสู่ระบบอีกครั้ง",
        okButtonVariant: "contained",
        onOk: async () => {
          window.location.reload()
          // @ts-ignore
          Modal.close()
        },
        disableBackdropClick: true,
      })
    }

    const onIdle = () => {
      const token = getToken()
      if (token) expireTimeAlert()
    }

    const onActive = () => {}
    const onAction = () => {
      localStorage.setItem("lastActiveTime", new Date().toISOString())
    }
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
      onIdle,
      onActive,
      onAction,
      crossTab: true,
      timeout: 1000000 * 900,
      events: ["mousemove", "keydown", "mousedown", "touchstart", "wheel", "mousewheel"],
    })
    useEffect(() => {
      const lastActiveTime = localStorage.getItem("lastActiveTime")
      const token = getToken()
      if (lastActiveTime !== null && token) {
        const lastActiveTimeExpire = new Date().getTime() - new Date(lastActiveTime).getTime()
        if (lastActiveTimeExpire >= 1000 * 900) {
          handleClickLogout()
        }
      }
    }, [])

    useEffect(() => {
      if (isAuthorized) fetchSetting()
    }, [fetchSetting, isAuthorized])

    return {
      initialized,
      isAuthorized,
      loading: pageLoading,
      isPreview,
    }
  }),
)

export default enhancer(Routes)
