import { compose, defaultProps, withHooks } from "enhancers"
import { Box } from "components"
import { DateTimePicker as FormikDateTimePicker } from "formik-material-ui-pickers"
import { format } from "date-fns"
import { get } from "lodash"
import { ReactComponent as CalendarIcon } from "assets/icon/using/calendar.svg"

import { AppColor } from "theme/app-color"
import styled from "styled-components"

const CustomFormikMuiDateTimePicker = styled(FormikDateTimePicker)`
  .MuiPickersDatePickerRoot-toolbar {
    background-color: ${AppColor["Primary/Primary Text"]} !important;
  }
`

const DateTimePicker = (props: any) => (
  <Box width={props.width} position="relative">
    <CustomFormikMuiDateTimePicker {...props} />
    <Box position="absolute" top={12} right={12}>
      <CalendarIcon />
    </Box>
  </Box>
)

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy, HH:mm", placeholder: "วันเวลา" }),
  withHooks(
    (
      props: {
        form: { touched: any; submitCount: number; errors: any }
        field: { name: any; value: any }
        format: string
      },
      hooks: { useMemo: any },
    ) => {
      const { useMemo } = hooks

      const error = useMemo(() => {
        const touched = get(props.form.touched, props.field.name) || props.form.submitCount > 0
        const error = get(props.form.errors, props.field.name)
        return touched && !!error
      }, [props.form, props.field])

      return {
        ...props,
        error,
        labelFunc: (date: number | Date, invalidLabel: any) => (props.field.value ? format(date, props.format) : ""),
      }
    },
  ),
)

export default enhancer(DateTimePicker)
