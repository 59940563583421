import { Box, Grid, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import PeriodSection from "components/PeriodSection"
import { compose, withHooks } from "enhancers"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const ContainerFlex = styled.div<{ js: string; row?: boolean; w100?: boolean; mt?: string; al?: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  width: ${(props) => (props.w100 ? "100%" : "none")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  align-items: ${(props) => props.al};
`

const NotifyBox = styled(Box)`
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 8px;
  background: ${AppColor["System/Info Light"]};
`

const ShowAllRequests = (props: any) => (
  <>
    {props.validateErrorText && (
      <NotifyBox>
        <MuiIcon
          fontSize="small"
          color={AppColor["System/Info"]}
          name="Info"
          style={{ width: "16px", height: "16px", marginRight: "4px" }}
        ></MuiIcon>
        <Typography variant="Body/14">{props.validateErrorText}</Typography>
      </NotifyBox>
    )}
    {props.notifyBeforeStartAtText && (
      <NotifyBox>
        <MuiIcon
          fontSize="small"
          color={AppColor["System/Info"]}
          name="Info"
          style={{ width: "16px", height: "16px", marginRight: "4px" }}
        ></MuiIcon>
        <Typography variant="Body/14">{props.notifyBeforeStartAtText}</Typography>
      </NotifyBox>
    )}
    {props.isShowPeriodSection && <PeriodSection mt="16px" mb="8px" p="8px" textColor="Text/Line" isHaveBorder />}

    <Grid container>
      {props.layouts.map((layout: any, index: number) => (
        <>
          <ContainerFlex js="start" w100>
            <ContainerFlex js="flex-start" al="center" row w100 mt={index === 0 ? "8px" : "32px"}>
              <Box mr="12px" display="flex" alignItems="center" justifyContent="center">
                <MuiIcon name={layout.icon} fontSize="small" color={layout.iconColor} />
              </Box>
              <Typography variant="h3" color={layout.titleColor}>
                {layout.title}
              </Typography>
            </ContainerFlex>
            <Box mt="4px" mb="16px">
              <Typography variant="subtitle2" color={AppColor["Text/Secondary Text"]}>
                {layout.description}
              </Typography>
            </Box>
          </ContainerFlex>
          {layout.sectionList.map((section: any) => (
            <>
              <Grid container spacing={3}>
                {section.requestTypeList.map((requestName: string, index: number) => {
                  const request = props.claimRequests[requestName]
                  const budgetValues = props.budgets[requestName]?.list || {}
                  if (request)
                    return (
                      <ClaimCard
                        key={requestName}
                        onClick={() => props.handleClickRequest({ name: requestName })}
                        type={request.type}
                        name={requestName}
                        approvedRequest={request.approvedCount}
                        totalAmount={request.approvedAmount}
                        title={request.title}
                        icon={request.icon}
                        iconColor={request.iconColor}
                        chipTitleColor={request.chipTitleColor}
                        chipBackgroundColor={request.chipBackgroundColor}
                        budget={request.budget ? request.budget.list : null}
                        budgetValues={budgetValues}
                        yearlyBudget={request.type === "ค่ารักษาพยาบาล" ? props.yearlyBudget : {}}
                      />
                    )

                  return <></>
                })}
              </Grid>
            </>
          ))}
        </>
      ))}
    </Grid>
  </>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    return { ...props }
  }),
)

export default enhancer(ShowAllRequests)
